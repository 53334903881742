import type {NextPage} from 'next';
import dynamic from 'next/dynamic';

const NoSSRUploadComponent = dynamic(async () => await import('components/Upload'), {
  ssr: false,
});

const Home: NextPage = () => {
  return <NoSSRUploadComponent />;
};

export default Home;
